<template>
    <footer>
      <div class="container-fluid">
        <div class="footer">

        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'AppFooter'
  };
  </script>
