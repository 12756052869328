import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'animate.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/css/custom.css';
import IntersectionObserverMixin from './mixins/IntersectionObserverMixin';

const app = createApp(App);

app.mixin(IntersectionObserverMixin);

app.use(router);
app.use(VueCookies);
app.mount('#app');