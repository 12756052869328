<template>
    <main>
      <section class="container-fluid d-flex text-center">
        <div v-if="showMisty" id="misty" class="route-holder d-flex d-lg-block flex-column flex-lg-row">
          <div class="position-relative animation-up d-lg-none">
            <div class="path-mobile">
              <div id="route-misty-0">
                  <h2>Misty Mountains</h2 >
                    <h3 class="text-transform-normal">Experience the allure of misty peaks, cool winds, and the embrace of nature.</h3>
                </div>
                <div id="route-misty-1">
                  <div class="numbering">1</div>
                  <img src="@/assets/img/place/1-1.png" class="img-fluid place-image">
                  <div class="place-info">
                    <h3 class="place-intro">BE COCOONED IN CLOUDS</h3 >
                    <h4 class="place-name">Awanmulan Retreats</h4>
                    <div class="address">Awanmulan Pantai Orchard,<br> 
                      Kampung Baru Pantai, 71770, Negeri Sembilan</div>
                  </div>
                </div>
                <div id="route-misty-2">
                  <div class="numbering">2</div>
                  <img src="@/assets/img/place/1-2.png" class="img-fluid place-image">
                  <div class="place-info">
                    <h3 class="place-intro">CARDIO ADVENTURE THROUGH GENTLE FORESTS</h3 >
                    <h4 class="place-name">Gunung Angsi</h4>
                    <div class="address">Gunung Angsi Entrance, <br>
                      361 Jalan Bukit Putus, Seremban</div>
                  </div>
                </div>
                <div id="route-misty-3">
                  <div class="numbering">3</div>
                  <img src="@/assets/img/place/1-3.png" class="img-fluid place-image">
                  <div class="place-info">
                    <h3 class="place-intro">TAKE A DIP IN COOL,
                      REFRESHING WATERS</h3 >
                    <h4 class="place-name">Ulu Bendol</h4>
                    <div class="address">Taman Rekreasi, 71500 Ulu Bendul,<br>
                      Negeri Sembilan, Malaysia</div>
                  </div>
                </div>
            </div>
            <div class="slider-controls">
                  <button class="btn btn-link control-prev"><img src="@/assets/img/i-prev.svg"></button>
                  <button class="btn btn-link control-next"><img src="@/assets/img/i-next.svg"></button>
            </div>
          </div >

          <div class="routemap position-absolute route-misty-0 animation-left">
            <div class="map-holder">
              <div class="stop-1"></div>
              <div class="stop-2"></div>
              <div class="stop-3"></div>
              <a href="/ai-planner">Want other ideas? Ask ChatGPT.</a>
            </div>
            <a href="/ai-planner" class="other-ideas d-lg-none">Want other ideas? Ask ChatGPT.</a>
          </div>
          <div class="route-info-slider animation-up d-none d-lg-block">
              <div id="route-misty-0">
                <h2>Misty Mountains</h2 >
                  <h3 class="text-transform-normal">Experience the allure of misty peaks, cool winds, and the embrace of nature.</h3>
              </div>
              <div id="route-misty-1">
                <div class="numbering">1</div>
                <img src="@/assets/img/place/1-1.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">BE COCOONED IN CLOUDS</h3 >
                  <h4 class="place-name">Awanmulan Retreats</h4>
                  <div class="address">Awanmulan Pantai Orchard,<br> 
                    Kampung Baru Pantai, 71770, Negeri Sembilan</div>
                </div>
              </div>
              <div id="route-misty-2">
                <div class="numbering">2</div>
                <img src="@/assets/img/place/1-2.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">CARDIO ADVENTURE THROUGH GENTLE FORESTS</h3 >
                  <h4 class="place-name">Gunung Angsi</h4>
                  <div class="address">Gunung Angsi Entrance, <br>
                    361 Jalan Bukit Putus, Seremban</div>
                </div>
              </div>
              <div id="route-misty-3">
                <div class="numbering">3</div>
                <img src="@/assets/img/place/1-3.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">TAKE A DIP IN COOL,
                    REFRESHING WATERS</h3 >
                  <h4 class="place-name">Ulu Bendol</h4>
                  <div class="address">Taman Rekreasi, 71500 Ulu Bendul,<br>
                    Negeri Sembilan, Malaysia</div>
                </div>
                <router-link to="/#path" class="btn btn-warning mt-lg-5 mt-3">CHOOSE A DIFFERENT PATH</router-link >
              </div>
          </div>

          <div id="indicator-1" class="scroll-indicator">
            <div @click="scrollToSection('route-misty-0')" class="active"></div>
            <div @click="scrollToSection('route-misty-1')"></div>
            <div @click="scrollToSection('route-misty-2')"></div>
            <div @click="scrollToSection('route-misty-3')"></div>
          </div>

          <router-link to="/#path" class="btn btn-warning mt-4 mx-auto  d-lg-none">CHOOSE A DIFFERENT PATH</router-link >


        </div>
        <div v-if="showNature" id="nature" class="route-holder d-flex d-lg-block flex-column flex-lg-row">
          <div class="position-relative animation-up d-lg-none">
            <div class="path-mobile">
              <div id="route-nature-0">
                <h2>Nature Whispers</h2 >
                  <h3 class="text-transform-normal">Escape to scenic wonders at a private campsite.</h3>
              </div>
              <div id="route-nature-1">
                <div class="numbering">1</div>
                <img src="@/assets/img/place/2-1.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">PICNIC BY THE GUSHING WATERS</h3 >
                  <h4 class="place-name">Jeram Kedah Waterfalls</h4>
                  <div class="address">71750 Beranang,<br>
                    Negeri Sembilan</div>
                </div>
              </div>
              <div id="route-nature-2">
                <div class="numbering">2</div>
                <img src="@/assets/img/place/2-2.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">UP FOR A CHALLENGING HIKE?</h3 >
                  <h4 class="place-name">Gunung Tok Wan Peak</h4>
                  <div class="address">Bukit Sungai Putih Forest Reserve,<br>
                    Semenyih, Selangor</div>
                </div>
              </div>
              <div id="route-nature-3">
                <div class="numbering">3</div>
                <img src="@/assets/img/place/2-3.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">ESCAPE TO NATURE'S
                    EMBRACE ON A HILLTOP</h3 >
                  <h4 class="place-name">YAKA Campsite</h4>
                  <div class="address">Jalan Broga, 71750 Lenggeng,Negeri Sembilan</div>

                  <!-- <div class="note">
                    [Peugeot Perks] RM20 off per night's stay<br>
                    *Flash your Peugeot Discovery Weekend comformation email to redeem
                  </div> -->
                </div>
              </div>
            </div>
            <div class="slider-controls">
                  <button class="btn btn-link control-prev"><img src="@/assets/img/i-prev.svg"></button>
                  <button class="btn btn-link control-next"><img src="@/assets/img/i-next.svg"></button>
            </div>
          </div >

          <div class="routemap position-absolute route-misty-0 animation-left">
            <div class="map-holder">
              <div class="stop-1"></div>
              <div class="stop-2"></div>
              <div class="stop-3"></div>
              <a href="/ai-planner">Want other ideas? Ask ChatGPT.</a>
            </div>
            <a href="/ai-planner" class="other-ideas d-lg-none">Want other ideas? Ask ChatGPT.</a>

          </div>
          <div class="route-info-slider animation-up d-none d-lg-block">
              <div id="route-nature-0">
                <h2>Nature Whispers</h2 >
                  <h3 class="text-transform-normal">Escape to scenic wonders at a private campsite.</h3>
              </div>
              <div id="route-nature-1">
                <div class="numbering">1</div>
                <img src="@/assets/img/place/2-1.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">PICNIC BY THE GUSHING WATERS</h3 >
                  <h4 class="place-name">Jeram Kedah Waterfalls</h4>
                  <div class="address">71750 Beranang,<br>
                    Negeri Sembilan</div>
                </div>
              </div>
              <div id="route-nature-2">
                <div class="numbering">2</div>
                <img src="@/assets/img/place/2-2.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">UP FOR A CHALLENGING HIKE?</h3 >
                  <h4 class="place-name">Gunung Tok Wan Peak</h4>
                  <div class="address">Bukit Sungai Putih Forest Reserve, <br>Semenyih, Selangor</div>
                </div>
              </div>
              <div id="route-nature-3">
                <div class="numbering">3</div>
                <img src="@/assets/img/place/2-3.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">ESCAPE TO NATURE'S
                    EMBRACE ON A HILLTOP</h3 >
                  <h4 class="place-name">YAKA Campsite</h4>
                  <div class="address">Jalan Broga, 71750 Lenggeng,Negeri Sembilan</div>

                  <!-- <div class="note">
                    [Peugeot Perks] RM20 off per night's stay<br>
                    *Flash your Peugeot Discovery Weekend comformation email to redeem
                  </div> -->
                </div>
                <router-link to="/#path" class="btn btn-warning mt-lg-5 mt-3">CHOOSE A DIFFERENT PATH</router-link >
              </div>
          </div>

          <div id="indicator-2" class="scroll-indicator">
            <div @click="scrollToSection('route-nature-0')" class="active"></div>
            <div @click="scrollToSection('route-nature-1')"></div>
            <div @click="scrollToSection('route-nature-2')"></div>
            <div @click="scrollToSection('route-nature-3')"></div>
          </div>

          <router-link to="/#path" class="btn btn-warning mt-4 mx-auto  d-lg-none">CHOOSE A DIFFERENT PATH</router-link >

        </div>
        <div v-if="showCulinary" id="culinary" class="route-holder d-flex d-lg-block flex-column flex-lg-row">
          <div class="position-relative animation-up d-lg-none">
            <div class="path-mobile">
              <div id="route-culinary-0">
                <h2>A Culinary Cruise</h2 >
                <h3 class="text-transform-normal">Pack up for a gastronomic adventure across foodie stops in Penang.</h3>
              </div>
              <div id="route-culinary-1">
                <div class="numbering">1</div>
                <img src="@/assets/img/place/3-1.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">A classic breakfast spot</h3 >
                  <h4 class="place-name">Roti Canai Transfer Road</h4>
                  <div class="address">114, 110, Jalan Transfer, George Town, <br>10050 George Town,Pulau Pinang</div>
                </div>
              </div>
              <div id="route-culinary-2">
                <div class="numbering">2</div>
                <img src="@/assets/img/place/3-2.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">Curry, spice and all things nice</h3 >
                  <h4 class="place-name">Nasi Kandar Imigresen
                  </h4>
                  <div class="address">8, Lorong Pasar, Georgetown, George Town, Penang</div>
                </div>
              </div>
              <div id="route-culinary-3">
                <div class="numbering">3</div>
                <img src="@/assets/img/place/3-3.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">Satisfy sweet cravings with a caffeine kick</h3 >
                  <h4 class="place-name">Chinahouse Cafe</h4>
                  <div class="address">153, Beach St, Georgetown, 10300 George Town, Penang</div>

                  <!-- <div class="note">
                    [Peugeot Perks] 15% off your total bill<br>
                    *Flash your Peugeot Discovery Weekend confirmation email to redeem
                  </div> -->
                </div>
              </div>
              <div id="route-culinary-4">
                <div class="numbering">4</div>
                <img src="@/assets/img/place/3-4.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">Hawker-style
                    heritage food</h3 >
                  <h4 class="place-name">Hameed Pata Mee Sotong
                  </h4>
                  <div class="address">5, Esplanade Park, Lebuh Light, <br>10300 George Town</div>
                </div>

              </div>
            </div>
            <div class="slider-controls">
                  <button class="btn btn-link control-prev"><img src="@/assets/img/i-prev.svg"></button>
                  <button class="btn btn-link control-next"><img src="@/assets/img/i-next.svg"></button>
            </div>
          </div>
          <div class="routemap position-absolute animation-left">
            <div class="map-holder">
              <div class="stop-1"></div>
              <div class="stop-2"></div>
              <div class="stop-3"></div>
              <div class="stop-4"></div>
              <a href="/ai-planner">Want other ideas? Ask ChatGPT.</a>
            </div>
<a href="/ai-planner" class="other-ideas d-lg-none">Want other ideas? Ask ChatGPT.</a>
          </div>
          <div class="route-info-slider animation-up d-none d-lg-block">
              <div id="route-culinary-0">
                <h2>A Culinary Cruise</h2 >
                <h3 class="text-transform-normal">Pack up for a gastronomic adventure across foodie stops in Penang.</h3>
              </div>
              <div id="route-culinary-1">
                <div class="numbering">1</div>
                <img src="@/assets/img/place/3-1.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">A classic breakfast spot</h3 >
                  <h4 class="place-name">Roti Canai Transfer Road</h4>
                  <div class="address">114, 110, Jalan Transfer, George Town, <br>10050 George Town,Pulau Pinang</div>
                </div>
              </div>
              <div id="route-culinary-2">
                <div class="numbering">2</div>
                <img src="@/assets/img/place/3-2.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">Curry, spice and all 
                    things nice</h3 >
                  <h4 class="place-name">Nasi Kandar Imigresen
                  </h4>
                  <div class="address">8, Lorong Pasar, Georgetown, George Town, Penang</div>
                </div>
              </div>
              <div id="route-culinary-3">
                <div class="numbering">3</div>
                <img src="@/assets/img/place/3-3.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">Satisfy sweet cravings with a caffeine kick</h3 >
                  <h4 class="place-name">Chinahouse Cafe</h4>
                  <div class="address">153, Beach St, Georgetown, 10300 George Town, Penang</div>

                  <!-- <div class="note">
                    [Peugeot Perks] 15% off your total bill<br>
                    *Flash your Peugeot Discovery Weekend confirmation email to redeem
                  </div> -->
                </div>
              </div>
              <div id="route-culinary-4">
                <div class="numbering">4</div>
                <img src="@/assets/img/place/3-4.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">Hawker-style
                    heritage food</h3 >
                  <h4 class="place-name">Hameed Pata Mee Sotong
                  </h4>
                  <div class="address">5, Esplanade Park, Lebuh Light, <br>10300 George Town</div>
                </div>

                <router-link to="/#path" class="btn btn-warning mt-lg-5 mt-3">CHOOSE A DIFFERENT PATH</router-link >
              </div>
          </div>

          <div id="indicator-3" class="scroll-indicator">
            <div @click="scrollToSection('route-culinary-0')" class="active"></div>
            <div @click="scrollToSection('route-culinary-1')"></div>
            <div @click="scrollToSection('route-culinary-2')"></div>
            <div @click="scrollToSection('route-culinary-3')"></div>
            <div @click="scrollToSection('route-culinary-4')"></div>
          </div>

          <router-link to="/#path" class="btn btn-warning mt-4 mx-auto  d-lg-none">CHOOSE A DIFFERENT PATH</router-link >

        </div>
        <div v-if="showBeaten" id="beaten" class="route-holder d-flex d-lg-block flex-column flex-lg-row">
          <div class="position-relative animation-up d-lg-none">
            <div class="path-mobile">
              <div id="route-beaten-0">
                <h2>OFF THE BEATEN TRACK</h2 >
                  <h3 class="text-transform-normal">Go off-road and explore the charms of a hidden small town.</h3>
              </div>
              <div id="route-beaten-1">
                <div class="numbering">1</div>
                <img src="@/assets/img/place/4-1.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">A PAMPERING SESSION WITHIN NATURE</h3 >
                  <h4 class="place-name">Hulu Tamu Hotsprings</h4>
                  <div class="address">Hulu Tamu, 44300<br>
                    Selangor</div>
                </div>
              </div>
              <div id="route-beaten-2">
                <div class="numbering">2</div>
                <img src="@/assets/img/place/4-2.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">MARVEL AT TRADITIONAL ARCHITECTURE</h3 >
                  <h4 class="place-name">Kampung Bukit Chandang</h4>
                  <div class="address">44200 Batang Kali,<br>
                    Selangor</div>
                </div>
              </div>
              <div id="route-beaten-3">
                <div class="numbering">3</div>
                <img src="@/assets/img/place/4-3.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">A QUAINT CAFE WITH <br>
                    LUSH PANORAMIC VIEWS</h3 >
                  <h4 class="place-name">KEBUN CAFE</h4>
                  <div class="address">29, Jalan Patin 2, Taman Patin, Hulu Yam Lama, <br>44300 Batang Kali, Selangor
                  </div>

                  <!-- <div class="note">
                    [Peugeot Perks] Enjoy a complimentary slice of cake with purchase of 2 beverages.<br>
                    *Flash your Peugeot Discovery Weekend
                    confirmation email to redeem
                  </div> -->
                </div>
              </div>
            </div>
            <div class="slider-controls">
                  <button class="btn btn-link control-prev"><img src="@/assets/img/i-prev.svg"></button>
                  <button class="btn btn-link control-next"><img src="@/assets/img/i-next.svg"></button>
            </div>
          </div>

          <div class="routemap position-absolute route-beaten-0 animation-left">
            <div class="map-holder">
              <div class="stop-1"></div>
              <div class="stop-2"></div>
              <div class="stop-3"></div>
              <a href="/ai-planner">Want other ideas? Ask ChatGPT.</a>
            </div>
            <a href="/ai-planner" class="other-ideas d-lg-none">Want other ideas? Ask ChatGPT.</a>
          </div>
          <div class="route-info-slider animation-up d-none d-lg-block">
              <div id="route-beaten-0">
                <h2>OFF THE BEATEN TRACK</h2 >
                  <h3 class="text-transform-normal">Go off-road and explore the charms of a hidden small town.</h3>
              </div>
              <div id="route-beaten-1">
                <div class="numbering">1</div>
                <img src="@/assets/img/place/4-1.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">A PAMPERING SESSION WITHIN NATURE</h3 >
                  <h4 class="place-name">Hulu Tamu Hotsprings</h4>
                  <div class="address">Hulu Tamu, 44300<br>
                    Selangor</div>
                </div>
              </div>
              <div id="route-beaten-2">
                <div class="numbering">2</div>
                <img src="@/assets/img/place/4-2.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">MARVEL AT TRADITIONAL ARCHITECTURE</h3 >
                  <h4 class="place-name">Kampung Bukit Chandang</h4>
                  <div class="address">44200 Batang Kali,<br>
                    Selangor</div>
                </div>
              </div>
              <div id="route-beaten-3">
                <div class="numbering">3</div>
                <img src="@/assets/img/place/4-3.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">A QUAINT CAFE WITH <br>
                    LUSH PANORAMIC VIEWS</h3 >
                  <h4 class="place-name">KEBUN CAFE</h4>
                  <div class="address">29, Jalan Patin 2, Taman Patin, Hulu Yam Lama, <br>44300 Batang Kali, Selangor
                  </div>

                  <!-- <div class="note">
                    [Peugeot Perks] Enjoy a complimentary slice of cake with purchase of 2 beverages.<br>
                    *Flash your Peugeot Discovery Weekend
                    confirmation email to redeem
                  </div> -->
                </div>
                <router-link to="/#path" class="btn btn-warning mt-lg-5 mt-3">CHOOSE A DIFFERENT PATH</router-link >
              </div>
          </div>

          <div id="indicator-4" class="scroll-indicator">
            <div @click="scrollToSection('route-beaten-0')" class="active"></div>
            <div @click="scrollToSection('route-beaten-1')"></div>
            <div @click="scrollToSection('route-beaten-2')"></div>
            <div @click="scrollToSection('route-beaten-3')"></div>
          </div>

          <router-link to="/#path" class="btn btn-warning mt-4 mx-auto  d-lg-none">CHOOSE A DIFFERENT PATH</router-link >

        </div>
        <div v-if="showOcean" id="ocean" class="route-holder d-flex d-lg-block flex-column flex-lg-row">
          <div class="position-relative animation-up d-lg-none">
            <div class="path-mobile">
              <div id="route-ocean-0">
                <h2>THE OCEAN CALLS</h2 >
                  <h3 class="text-transform-normal">Surrender to the soothing rhythm of the waves.</h3>
              </div>
              <div id="route-ocean-1">
                <div class="numbering">1</div>
                <img src="@/assets/img/place/5-1.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">THE WORLD'S LONGEST
                    CURVE SUSPENSION BRIDGE</h3 >
                  <h4 class="place-name">Langkawi Sky Bridge</h4>
                  <div class="address">Langkawi Skybridge Cable Car, Jalan Telaga Tujuh, <br>
                    07000 Langkawi, Kedah, Malaysia</div>
                </div>
              </div>
              <div id="route-ocean-2">
                <div class="numbering">2</div>
                <img src="@/assets/img/place/5-2.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">HOP FROM ONE PARADISE<br>TO ANOTHER</h3 >
                  <h4 class="place-name">Island Hopping Langkawi - Alanona</h4>
                  <div class="address">Pantai Teluk Baru, Jeti Island Hopping, Jalan Pantai Tengah,<br> 07000 Langkawi, Kedah</div>
                </div>
              </div>
              <div id="route-ocean-3">
                <div class="numbering">3</div>
                <img src="@/assets/img/place/5-3.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">MESMERISING LANGKAWI SUNSET <br>ON A LUXURY CRUISE</h3 >
                  <h4 class="place-name">Sunset Cruise Langkawi</h4>
                  <div class="address">Jetty Marble Kuah, 07000 Langkawi,<br>
                    Kedah, Malaysia</div>
                </div>
              </div>
            </div>
            <div class="slider-controls">
                  <button class="btn btn-link control-prev"><img src="@/assets/img/i-prev.svg"></button>
                  <button class="btn btn-link control-next"><img src="@/assets/img/i-next.svg"></button>
            </div>
          </div>

          <div class="routemap position-absolute route-ocean-0 animation-left">
            <div class="map-holder">
              <div class="stop-1"></div>
              <div class="stop-2"></div>
              <div class="stop-3"></div>
              <a href="/ai-planner">Want other ideas? Ask ChatGPT.</a>
            </div>
            <a href="/ai-planner" class="other-ideas d-lg-none">Want other ideas? Ask ChatGPT.</a>
          </div>
          <div class="route-info-slider animation-up d-none d-lg-block">
              <div id="route-ocean-0">
                <h2>THE OCEAN CALLS</h2 >
                  <h3 class="text-transform-normal">Surrender to the soothing rhythm of the waves.</h3>
              </div>
              <div id="route-ocean-1">
                <div class="numbering">1</div>
                <img src="@/assets/img/place/5-1.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">THE WORLD'S LONGEST
                    CURVE SUSPENSION BRIDGE</h3 >
                  <h4 class="place-name">Langkawi Sky Bridge</h4>
                  <div class="address">Langkawi Skybridge Cable Car, Jalan Telaga Tujuh, <br>
                    07000 Langkawi, Kedah, Malaysia</div>
                </div>
              </div>
              <div id="route-ocean-2">
                <div class="numbering">2</div>
                <img src="@/assets/img/place/5-2.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">HOP FROM ONE PARADISE<br>TO ANOTHER</h3 >
                  <h4 class="place-name">Island Hopping Langkawi - Alanona</h4>
                  <div class="address">Pantai Teluk Baru, Jeti Island Hopping, Jalan Pantai Tengah,<br> 07000 Langkawi, Kedah</div>
                </div>
              </div>
              <div id="route-ocean-3">
                <div class="numbering">3</div>
                <img src="@/assets/img/place/5-3.png" class="img-fluid place-image">
                <div class="place-info">
                  <h3 class="place-intro">MESMERISING LANGKAWI SUNSET <br>ON A LUXURY CRUISE</h3 >
                  <h4 class="place-name">Sunset Cruise Langkawi</h4>
                  <div class="address">Jetty Marble Kuah, 07000 Langkawi,<br>
                    Kedah, Malaysia</div>
                </div>
                <router-link to="/#path" class="btn btn-warning mt-lg-5 mt-3">CHOOSE A DIFFERENT PATH</router-link >
              </div>
          </div>

          <div id="indicator-5" class="scroll-indicator">
            <div @click="scrollToSection('route-ocean-0')" class="active"></div>
            <div @click="scrollToSection('route-ocean-1')"></div>
            <div @click="scrollToSection('route-ocean-2')"></div>
            <div @click="scrollToSection('route-ocean-3')"></div>
          </div>

          <router-link to="/#path" class="btn btn-warning mt-4 mx-auto  d-lg-none">CHOOSE A DIFFERENT PATH</router-link >
        </div>
        
        <a href="#book" id="scroll-to-book" class="animate__animated animate__fadeIn animate__faster" v-show="showScrollToBook" @click.prevent="scrollToBook">
          <img class="" src="@/assets/img/i-double-arrow-down.svg">
          Scroll to book your weekend test drive
        </a>

        <!-- <div class="animate__animated animate__fadeIn animate__faster scroll-down">
            <img class="" src="@/assets/img/i-double-arrow-down.svg">
          </div> -->
      </section>
      <div id="book">
        <section class="container animate__animated animate__fadeInRight animate__faster">
            <div class="row">
              <div class="col-lg-6">
                <h2 class="h1">BOOK YOUR<br class="d-none d-lg-block">
                WEEKEND<br class="d-none d-lg-block">
                TEST DRIVE</h2 >
    
                <p>Ready to get behind the wheel?</p>
              </div>
              <div class="col-lg-6">
                  <div class="form-box">
                    <iframe src="https://forms.office.com/Pages/ResponsePage.aspx?id=zdVS2ExyKEGIEv-l2z-FB5k7y7NIBJdHn_T5oxNw0WVUMFAwU0oxU1lRU1VFTVkzNzZSOElXRTNCUC4u&embed=true" frameborder="0" marginwidth="0" marginheight="0"></iframe>
                  </div>
              </div>
            </div>
        </section>
      </div>
    </main >
  </template>
  
  <script> 
  import $ from 'jquery';
  import 'slick-carousel';

  

  export default {
    name: 'PathPage',
    data() {
      return {
        showMisty: false,
        showNature: false,
        showCulinary: false,
        showBeaten: false,
        showOcean: false,
        showScrollToBook: false,
        lastVisibleSection: null,
        currentStop: 0,
      };
    },
    mounted() {
      this.checkRouteParam();
      this.scrollToSection();
      this.$nextTick(() => {
        this.setupScrollObserver();
        this.setupIndicatorObserver();
        this.setupScrollUpObserver();
        $('.path-mobile').on('afterChange', function(event, slick){
            const lastSlide = slick.$slides.last();
            this.showScrollToBook = lastSlide.attr('aria-hidden') === 'false';      
        }.bind(this));
      });
    },
    methods: {
      
      scrollToSection(id) {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      },
      checkRouteParam() {
        const urlParams = new URLSearchParams(window.location.search);
        const routeParam = urlParams.get('route');
        if (routeParam === '1') {
          this.showMisty = true;
          this.$nextTick(() => {
            this.updateRouteMap('route-misty-0');
            this.setupRouteObserver();
            this.initiateSlick('misty');
          });
        } else if (routeParam === '2') {
          this.showNature = true;
          this.$nextTick(() => {
            this.updateRouteMap('route-nature-0');
            this.setupRouteObserver();
            this.initiateSlick('nature');
          });
        } else if (routeParam === '3') {
          this.showCulinary = true;
          this.$nextTick(() => {
            this.updateRouteMap('route-culinary-0');
            this.setupRouteObserver();
            this.initiateSlick('culinary');
          });
        } else if (routeParam === '4') {
          this.showBeaten = true;
          this.$nextTick(() => {
            this.updateRouteMap('route-beaten-0');
            this.setupRouteObserver();
            this.initiateSlick('beaten');
          });
        } else if (routeParam === '5') {
          this.showOcean = true;
          this.$nextTick(() => {
            this.updateRouteMap('route-ocean-0');
            this.setupRouteObserver();
            this.initiateSlick('ocean');
          });
        }
      },
      initiateSlick(id) {
        $("#" + id + " .path-mobile").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          infinite: false,
          nextArrow: '.control-next',
          prevArrow: '.control-prev',
        });
      },
      setupRouteObserver() {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.5
        };

        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const routeId = entry.target.id;
              this.updateRouteMap(routeId);
            }
          });
        }, options);

        // Observe all route sections
        this.$nextTick(() => {
          const routeSections = document.querySelectorAll('[id^="route-"]');
          routeSections.forEach(section => {
            observer.observe(section);
          });
        });
      },
      updateRouteMap(routeId) {
        const routeMap = document.querySelector('.routemap');
        
        if (routeMap) {
          routeMap.classList.forEach(className => {
            if (className.startsWith('route-')) {
              routeMap.classList.remove(className);
            }
          });
          routeMap.classList.add(`${routeId}`);
        }
      },
      setupScrollObserver() {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 1.0
        };

        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.showScrollToBook = true;
            } else {
              this.showScrollToBook = false;
            }
          });
          // New condition to check the last slide visibility
          const lastSlide = document.querySelector('.path-mobile .slick-track > .slick-slide:last-child');
          if (lastSlide && lastSlide.getAttribute('aria-hidden') === 'false') {
            this.showScrollToBook = true;
          }
        }, options);

        // Observe the last child of each .route-info-slider
        this.$nextTick(() => {
          const lastChildren = document.querySelectorAll('.route-info-slider > div:last-child');
          lastChildren.forEach(child => {
            observer.observe(child);
          });
        });
      },
      setupIndicatorObserver() {
        const routeInfoSliderChildren = document.querySelectorAll('.route-info-slider > *');
        const scrollIndicatorChildren = document.querySelectorAll('.scroll-indicator > *');

        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const index = Array.from(routeInfoSliderChildren).indexOf(entry.target);
              scrollIndicatorChildren.forEach((child, i) => {
                if (i === index) {
                  child.classList.add('active');
                } else {
                  child.classList.remove('active');
                }
              });
            }
          });
        }, {
          threshold: 0.5
        });

        routeInfoSliderChildren.forEach(child => observer.observe(child));
      },
      setupScrollUpObserver() {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.5
        };

        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            const currentId = entry.target.id;
            if (entry.isIntersecting) {
              const currentStopNumber = parseInt(currentId.split('-')[2]);
              if (this.lastVisibleSection && this.isScrollingUp(this.lastVisibleSection, currentId)) {
                this.addFadingClass();
              }
              this.lastVisibleSection = currentId;
              this.currentStop = currentStopNumber;
            }
          });
        }, options);

        // Observe all route sections
        this.$nextTick(() => {
          const routeSections = document.querySelectorAll('[id^="route-"]');
          routeSections.forEach(section => {
            observer.observe(section);
          });
        });
      },

      isScrollingUp(lastSection, currentSection) {
        const sectionNumbers = {
          lastSection: parseInt(lastSection.split('-')[2]),
          currentSection: parseInt(currentSection.split('-')[2])
        };
        return sectionNumbers.currentSection < sectionNumbers.lastSection;
      },

      addFadingClass() {
        const routeHolder = document.querySelector('.route-holder');
        if (routeHolder) {
          const previousStop = routeHolder.querySelector(`.stop-${this.currentStop}`);
          if (previousStop) {
            previousStop.classList.add('fading');
            setTimeout(() => {
              previousStop.classList.remove('fading');
            }, 800);
          }
        }
      },

      scrollToBook() {
        const bookSection = document.getElementById('book');
        if (bookSection) {
          bookSection.scrollIntoView({ behavior: 'smooth' });
        }
      },
    }
  };

  document.addEventListener('DOMContentLoaded', () => {
    const routeInfoSliderChildren = document.querySelectorAll('.route-info-slider > *');
    const scrollIndicatorChildren = document.querySelectorAll('.scroll-indicator > *');

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const index = Array.from(routeInfoSliderChildren).indexOf(entry.target);
                scrollIndicatorChildren.forEach((child, i) => {
                    if (i === index) {
                        child.classList.add('active');
                    } else {
                        child.classList.remove('active');
                    }
                });
            }
        });
    }, {
        threshold: 0.5
    });

    routeInfoSliderChildren.forEach(child => observer.observe(child));
});
  </script>
