export default {
    mounted() {
      this.observeElements();
      this.observeScrollDown(); // Added this line
    },
    methods: {
      observeElements() {
        const elements = document.querySelectorAll('.animation-up, .animation-left');
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              if (entry.target.classList.contains('animation-up')) {
                entry.target.classList.add('animate__animated', 'animate__fadeInUp');
              } else if (entry.target.classList.contains('animation-left')) {
                entry.target.classList.add('animate__animated', 'animate__fadeInLeft');
              }
              observer.unobserve(entry.target); // Stop observing once the animation is added
            }
          });
        });

        elements.forEach(element => {
          observer.observe(element);
        });
      },
      observeScrollDown() { // New method to observe #summary
        const summary = document.querySelector('#summary h2');
        const scrollDown = document.querySelector('.scroll-down');

        // Check if elements exist
        if (!summary || !scrollDown) {
          return; // Exit if elements are not found
        }
        
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              scrollDown.style.display = 'none'; // Hide scroll-down when #summary is in view
            } else {
              scrollDown.style.display = 'block'; // Show scroll-down when #summary is out of view
            }
          });
        });

        observer.observe(summary);
      }
    }
  };