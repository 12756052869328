<template>
    <main>
        <!-- Cookie Policy Modal -->
        <div id="cookieModal" class="modal fade" :class="{ show : cookiepolicy }" tabindex="-1" role="dialog" :style="{ display: cookiepolicy ? 'block' : 'none' }" style="background-color: rgba(0, 0, 0, 0.5);">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div 
              class="modal-content" 
              style="border-radius: 0; padding: 20px; background-color: #ffffff; clip-path: polygon(0 0, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%);"
            >
              <div class="modal-body text-center">
                <p style="font-size: 16px; color: #333333;">
                  This website uses essential cookies to enhance your experience and provide you with personalized information.
                  By clicking "Accept All", you agree to our use of cookies as outlined in our <a href="/" style="color: rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1))">Cookie Policy</a>. You can change or
                  update your cookie preferences at any time by clicking "Preferences".
                </p>
              </div>
              <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn-success" style="background-color: #75795e; border: none; padding: 10px 20px; width: 300px; margin-top: 12px;" @click="acceptCookie">ACCEPT ALL</button>
                <button type="button" class="btn btn-secondary" style="background-color: #75795e; border: none; padding: 10px 20px; width: 300px; margin-top: 12px;" @click="closeModal">REJECT ALL</button>
              </div>
            </div>
          </div>
        </div>

      <section id="landing" class="container-fluid">
        <div class="container">
          <div class="ps-5 mb-5 d-none d-md-block">
            <img src="@/assets/img/heading-home_1.png" alt="Peugeot Landtrek, Every path a pleasure" width="500" class="img-fluid animation-up">
          </div>
          <div class="d-md-none animation-left">
            <img src="@/assets/img/weekender-landtrek.png" alt="Peugeot Weekender Experience - Landtrek Edition" class="img-fluid mb-3 mb-sm-0">
          </div >
        </div>
  
        <div class="row align-items-end animation-left">
          <div class="col-md-5 col-lg-4 col-xl-3">
            <img src="@/assets/img/weekender-landtrek.png" alt="Peugeot Weekender Experience - Landtrek Edition" class="img-fluid mb-3 mb-sm-0 d-none d-md-block">
          </div>
          <div class="col-md-7 col-lg-6 text-center text-md-start">
            <h6 class="fw-light fs-5 food-nature">FOOD. NATURE. HIDDEN GEMS.</h6>
            Test drive the Landtrek for an entire weekend and experience its versatility, power and comfort.

            <img src="@/assets/img/everypath.png" alt="Every path a pleasure" class="img-fluid mt-3 d-md-none" width="440">

          </div>
        </div>

        <div class="animate__animated animate__fadeIn animate__faster scroll-down">
          <img class="" src="@/assets/img/i-double-arrow-down.svg">
        </div>

      </section>

      <div id="home-path">
        <section id="path" class="container-fluid">
          <div class="container text-center">
            <h2 class="h1 animation-up">WHICH PATH CALLS OUT TO YOU?</h2>
            <h4 class="animation-up delay-0-5s">Embark on an adventure and test drive the Landtrek for a whole weekend.</h4 >
  
            <div class="path-slider my-md-5 animation-up delay-1s">
              <div class="path-selection">
                <div v-for="(item, index) in pathItems" 
                     :key="index" 
                     :class="{ 'slick-current': index === currentIndex }"
                     @mouseenter="handleMouseEnter(index)">
                  <a :href="'/path?route=' + (index + 1)">
                    <img :src="item.src" :alt="item.alt">
                  </a>
                </div>
              </div>
              <div class="slider-controls d-lg-none">
                <button class="btn btn-link control-prev" @click="prevSlide"><img src="@/assets/img/i-prev.svg"></button>
                <button class="btn btn-link control-next" @click="nextSlide"><img src="@/assets/img/i-next.svg"></button>
              </div>
            </div >
  
            <h4 class="animation-up delay-1-5s"><b class="fw-bold">Need more inspiration?</b> Discover your path to pleasure with our AI planner. </h4 >
  
            <div class="chatgpt-initiate my-2 my-md-4 animation-up delay-2s">
              <form @submit.prevent="submitForm">
                <div class="input-group">
                  <input class="form-control form-control-lg" type="text" v-model="userMessage" placeholder="What do you enjoy? State your starting point." id="prompt" autocomplete="off">
                  <button class="btn btn-light" type="submit" id="button-addon2">
                    <img src="@/assets/img/icon-search.svg" alt="Search" width="30" class="">
                  </button>
                </div>
                <div class="form-text text-light mb-3">
                  Eg: Beaches. KL | Food. Pahang | Culture. Johor.
                </div>
              </form>
            </div >
  
            <img src="@/assets/img/powered-chatgpt.svg" alt="Powered by ChatGPT" width="300" class="powered-chatgpt img-fluid animation-up delay-2s">
          </div>
        </section>
        <section id="watch" class="container-fluid">
          <div class="container-md text-center">
            <h2 class="h1 animation-up">PLEASURE IS IN THE DETAILS</h2>
            <h4 class="animation-up delay-0-5s">
              From its impressive exterior to a comfortable interior, <br class="d-none d-md-block">
              discover everything the Landtrek has to offer for your weekend escapades.</h4 >

              <div class="animation-up delay-1s video-container">
                <div class="ratio ratio-16x9">
                  <!-- <div id="play_button" class="stop"></div>
                  <video id="video" playsinline loop muted poster="@/assets/img/poster.jpg" >
                    <source src="@/assets/vid/sample.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                  </video > -->
                  <iframe src="https://www.youtube.com/embed/2r5fLv_HGxQ?rel=controls=0" title="YouTube video" allowfullscreen></iframe>
                </div>
              </div>

              <a href="https://www.peugeot.com.my/models/landtrek.html" target="_blank" class="animation-up delay-1-5s btn btn-warning d-inline-flex align-items-center justify-content-between gap-3">Discover the Landtrek</a>

          </div>
        </section>
        <section id="summary" class="container-fluid text-start">
          <div class="container-md">

            <h2 class="animation-left">Capture The Captivating</h2 >
            <div class="animation-up delay-0-5s">
              <h3>Upload your moments with the hashtags </h3 >
              <div class="hashtag">#PEUGEOTWEEKENDEREXPERIENCE</div>
              <div class="hashtag">#PeugeotMalaysia </div>
              <h3>for a chance to win.</h3>
            </div>
    
            <div class="hashtag-gallery px-sm-5 pe-md-4 ps-md-5">
              <div class="position-relative animation-up delay-1s">
                <div class="gallery ">
                  <div class="slide"><img src="@/assets/img/gallery/gallery-1.jpg"></div >
                  <div class="slide"><img src="@/assets/img/gallery/gallery-2.jpg"></div>
                  <div class="slide"><img src="@/assets/img/gallery/gallery-3.jpg"></div>
                  <div class="slide"><img src="@/assets/img/gallery/gallery-1.jpg"></div >
                  <div class="slide"><img src="@/assets/img/gallery/gallery-2.jpg"></div>
                  <div class="slide"><img src="@/assets/img/gallery/gallery-3.jpg"></div>
                  
                </div>
                <div class="slider-controls">
                    <button class="btn btn-link control-prev"><img src="@/assets/img/i-prev.svg"></button>
                    <button class="btn btn-link control-next"><img src="@/assets/img/i-next.svg"></button>
                  </div>
    
              </div>
    
            </div>
            <div class="d-block d-md-flex ps-md-5 pe-md-4 justify-content-between mt-4">
              <h4 class="animation-left delay-1s">BOOK YOUR WEEKEND <span class="d-inline-block">TEST DRIVE</span></h4 >
              <a href="/book" class="animation-up delay-1-5s btn btn-warning d-inline-flex align-items-center justify-content-between gap-3">Let's Go <img src="@/assets/img/button-arrow.svg" height="15"></a>
            </div>
          </div>
        </section>
      </div >
      
    </main>
  </template>
  
  <script>
import { onMounted, ref, onUnmounted } from 'vue';
import $ from 'jquery';
import 'slick-carousel';

export default {
  name: 'HomePage',
  setup() {
    const pathItems = ref([
      { src: require('@/assets/img/1-misty.jpg'), alt: 'Misty Mountains' },
      { src: require('@/assets/img/2-nature.jpg'), alt: 'Nature Whispers' },
      { src: require('@/assets/img/3-culinary.jpg'), alt: 'A Culinary Cruise' },
      { src: require('@/assets/img/4-beaten.jpg'), alt: 'Off the Beaten Track' },
      { src: require('@/assets/img/5-ocean.jpg'), alt: 'The Ocean Calls' }
    ]);
    const currentIndex = ref(0);
    const isSlickInitialized = ref(false);
    const windowWidth = ref(window.innerWidth);
    const cookiepolicy = ref(true);

    onMounted(() => {
      initSlider();
      window.addEventListener('resize', handleResize);

      // Add the video play/pause functionality
      // var video = document.getElementById("video");
      // var playButton = document.getElementById("play_button");
      // playButton.addEventListener("click", function() {
      //   if (video.paused == true) {
      //     video.play();
      //     playButton.classList.toggle("stop");
      //   } else {
      //     video.pause();
      //     playButton.classList.toggle("stop");
      //   }
      // });
    });

    onUnmounted(() => {
      destroySlider();
      window.removeEventListener('resize', handleResize);
    });

    const initSlider = () => {
      if (windowWidth.value < 992 && !isSlickInitialized.value) {
        $('.path-selection').slick({
          slidesToShow: 5,
          slidesToScroll: 1,
          dots: false,
          infinite: true,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true,
                centerMode: true,
                centerPadding: '0',
                nextArrow: '.control-next',
                prevArrow: '.control-prev',
              }
            },
            // {
            //   breakpoint: 767,
            //   settings: {
            //     slidesToShow: 3,
            //     slidesToScroll: 1,
            //     centerMode: true,
            //     dots: true,
            //     centerPadding: '40px',
            //     touchThreshold: 10,
            //   }
            // },
            // {
            //   breakpoint: 575,
            //   settings: {
            //     slidesToShow: 1,
            //     slidesToScroll: 1,
            //     centerMode: true,
            //     dots: true,
            //     centerPadding: '20%',
            //     touchThreshold: 10,
            //   }
            // }
          ]
        }).on('init', () => {
          $('.path-selection .slick-slide').removeClass('slick-current');
        }).on('afterChange', (event, slick, currentSlide) => {
          currentIndex.value = currentSlide;
        });
        isSlickInitialized.value = true;
      }
    };

    const destroySlider = () => {
      if (isSlickInitialized.value) {
        $('.path-selection').slick('unslick');
        isSlickInitialized.value = false;
      }
    };

    const handleResize = () => {
      const newWindowWidth = window.innerWidth;
      if (newWindowWidth >= 992 && windowWidth.value < 992) {
        destroySlider();
      } else if (newWindowWidth < 992 && windowWidth.value >= 992) {
        initSlider();
      }
      windowWidth.value = newWindowWidth;
    };

    const selectItem = (index) => {
      if (index !== currentIndex.value) {
        if (isSlickInitialized.value) {
          $('.path-selection').slick('slickGoTo', index);
        } else {
          currentIndex.value = index;
        }
      }
    };

    const prevSlide = () => {
      if (isSlickInitialized.value) {
        $('.path-selection').slick('slickPrev');
      } else {
        currentIndex.value = (currentIndex.value - 1 + pathItems.value.length) % pathItems.value.length;
      }
    };

    const nextSlide = () => {
      if (isSlickInitialized.value) {
        $('.path-selection').slick('slickNext');
      } else {
        currentIndex.value = (currentIndex.value + 1) % pathItems.value.length;
      }
    };

    const handleMouseEnter = (index) => {
      currentIndex.value = index;
    };

    return {
      pathItems,
      currentIndex,
      selectItem,
      prevSlide,
      nextSlide,
      isSlickInitialized,
      handleMouseEnter,
      cookiepolicy,
    };
  },
  mounted() {
    this.scrollToSection();
    this.$nextTick(() => {
        this.initializeSlickCarousel();
    });

    const cookieToken = this.$cookies.get('cookie_id');
      if (cookieToken) {
        this.cookiepolicy = false;
      } else {
        this.cookiepolicy = true;
      }

  },
  methods: {
    scrollToSection() {
      const hash = this.$route.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
    initializeSlickCarousel() {
      this.destroySlickCarousel();
      $('.gallery').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        nextArrow: '.control-next',
        prevArrow: '.control-prev',
        responsive: [
            {
              breakpoint: 767,
              settings: {
                dots: true,
                arrows: false,
              }
            }
          ]
      });
    },
    destroySlickCarousel() {
        if ($('.gallery').hasClass('slick-initialized')) {
          $('.gallery').slick('unslick');
        }
    },
    setupScrollObserver() {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 1.0
        };

        new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.$nextTick(() => {
                this.reinitializeSlickCarousel();
              });
            } 
          });
        }, options);

    },
    reinitializeSlickCarousel() {
      this.destroySlickCarousel();
      this.$nextTick(() => {
        this.initializeSlickCarousel();
      });
    },
    submitForm() {
      // Navigate to /ai-planner with the user message
      this.$router.push({ path: '/ai-planner', query: { message: this.userMessage } });
    },
    closeModal() {
      this.cookiepolicy = false;
    },
    async acceptCookie() {
        const response = await fetch('https://zrmj554iy0.execute-api.ap-southeast-1.amazonaws.com/production/cookie', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors'
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.$cookies.set('cookie_id', data.cookie_id);
        this.cookiepolicy = false;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$nextTick(() => {
        vm.initializeSlickCarousel();
      });
    });
  },
  beforeRouteUpdate(to, from, next) {
      this.$nextTick(() => {
      this.initializeSlickCarousel();
      });
    next();
  },
  beforeUnmount() {
      this.destroySlickCarousel();
  },
  data() {
    return {
      userMessage: '', // Store user input
    };
  },
};
</script>
