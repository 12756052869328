import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/Home.vue';
import PathPage from '@/views/Path.vue';
import AIPlanner from '@/views/AIPlanner.vue';
import BookPage from '@/views/Book.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/path',
    name: 'Path',
    component: PathPage
  },
  {
    path: '/ai-planner',
    name: 'AIPlanner',
    component: AIPlanner
  },
  {
    path: '/book',
    name: 'Book',
    component: BookPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to) => {
  // Remove existing template classes
  document.body.classList.forEach(className => {
    if (className.startsWith('template-')) {
      document.body.classList.remove(className);
    }
  });

  // Add new class based on route name
  if (to.name) {
    document.body.classList.add(`template-${to.name.toLowerCase()}`);
  }
});


export default router;
