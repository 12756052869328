<template>
    <main id="" class="container animate__animated animate__fadeInRight animate__faster">
      <div class="row">
        <div class="col-lg-6">
          <h1>BOOK YOUR<br class="d-none d-lg-block">
          WEEKEND<br class="d-none d-lg-block">
          TEST DRIVE</h1>

          <p>Ready to get behind the wheel?</p>
        </div>
        <div class="col-lg-6">
            <div class="form-box">
              <iframe src="https://forms.office.com/Pages/ResponsePage.aspx?id=zdVS2ExyKEGIEv-l2z-FB5k7y7NIBJdHn_T5oxNw0WVUMFAwU0oxU1lRU1VFTVkzNzZSOElXRTNCUC4u&embed=true" frameborder="0" marginwidth="0" marginheight="0"></iframe>
            </div>
        </div>
      </div>
    </main >
  </template>
  
  <script>
  export default {
    name: 'ContactPage',
  };
  </script>
  