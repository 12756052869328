<template>
  <main class="container animate__animated animate__fadeIn">

    
    <div id="chatbox">

      <a href="javascript:history.back()" class="btn-back">
        <img src="@/assets/img/i-back.png" height="30" alt="Back">
      </a>
    
      <div class="conversation-area" id="conversationArea">
        <div v-for="(msg, index) in conversation" :key="index">
          <div v-if="msg.type === 'chatgpt'" class="msg-chatgpt">
            <span v-html="parseMarkdown(msg.text)"></span> <!-- Render Markdown for ChatGPT response -->
          </div>
          <div v-else-if="msg.type === 'user'" class="msg-user">
            <span>{{ msg.text }}</span>
          </div>

        </div>
        <!-- Loader -->
        <div v-if="loading" class="loader">
            <div class="circle" id="circle-1"></div>
            <div class="circle" id="circle-2"></div>
            <div class="circle" id="circle-3"></div>
        </div>
      </div>

      <div class="prompt-area">
        <div class="input-group">
          <input 
            id="prompt" 
            type="text" 
            autocomplete="off"
            class="form-control" 
            v-model="userMessage" 
            placeholder="Type your message here..."
            @keyup.enter="sendMessage"
          >
          <button class="send-prompt btn btn-magic" @click="sendMessage" :disabled="loading">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-2xl"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z" fill="#fff"></path></svg>
          </button>
        </div>
      </div>
      
    </div>

    <div class="disclaimer">
      ChatGPT may provide inaccurate information. The use of this tool is at the user's own risk, and the user is solely responsible for any decisions or actions taken based on the information provided.
    </div>

    <router-link to="/book" class="btn btn-warning">BOOK A TEST DRIVE</router-link >
  </main >
</template>

<script>
import { marked } from 'marked'; // Correct way to import marked

export default {
  name: 'AIPlanner',
  data() {
    return {
      userMessage: this.$route.query.message || '', // Get message from query
      conversation: [], // Store conversation history
      loading: false,  // Initialize loading state
      cookieToken: null // Cookie Policy Id
    };
  },
  methods: {
    parseMarkdown(text) {
      return marked(text); // Convert Markdown to HTML
    },
    async sendMessage() {
      if (this.userMessage.trim() !== "") {
        try {
          const userMessage = this.userMessage;
          // Add user message to conversation
          this.conversation.push({ type: 'user', text: userMessage });
          // Clear the input field after message is sent
          this.userMessage = '';

          // Set loading to true before making the API request
          this.loading = true;

          // Call ChatGPT API
          const response = await fetch('https://zrmj554iy0.execute-api.ap-southeast-1.amazonaws.com/production/chat', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt: userMessage, cookie_id: this.cookieToken }),
            mode: 'cors'
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();

          // Add ChatGPT response to conversation
          this.conversation.push({ type: 'chatgpt', text: data.response });

        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          // Set loading to false after the response is received or error occurred
          this.loading = false;
        }
      }
    },
  },
  watch: {
    conversation: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          const conversationArea = document.getElementById("conversationArea");
          conversationArea.scrollTop = conversationArea.scrollHeight;
        });
      }
    }
  },
  mounted() {
    this.cookieToken = this.$cookies.get('cookie_id');
    if (this.userMessage) {
      this.sendMessage(); // Automatically send the message if it exists
    }
  },
};
</script>
